import { IDR } from '@/libs/currency'

/* eslint-disable import/prefer-default-export */
export const performaMitraFields = [
  {
    key: 'warehouse_name',
    label: 'Mitra Gudang',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '13px',
      color: 'black',
    },
    tdClass: 'text-black',
    class: 'p-1 align-top',
    sortable: true,
  },
  {
    key: 'location',
    label: 'Kota',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '13px',
      color: 'black',
    },
    tdClass: 'text-black',
    class: 'p-1 align-top',
    sortable: true,
  },
  {
    key: 'total_outbounds',
    label: 'Outbond',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '13px',
      color: 'black',
    },
    tdClass: 'text-black',
    class: 'p-1 align-top',
    sortable: true,
  },
  {
    key: 'total_mitra_earnings',
    label: 'Omset',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '13px',
      color: 'black',
    },
    tdClass: 'text-black',
    class: 'p-1 align-top',
    sortable: true,
    formatter: value => IDR(value).replace('.', ''),
  },
  {
    key: 'total_kompack_revenue',
    label: 'Revenue Kompack',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '13px',
      color: 'black',
    },
    tdClass: 'text-black',
    class: 'p-1 align-top',
    sortable: true,
    formatter: value => IDR(value).replace('.', ''),
  },
]

export const performaPartnerFields = [
  {
    key: 'partner_name',
    label: 'Nama Partner',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '13px',
      color: 'black',
    },
    tdClass: 'text-black',
    class: 'p-1 align-top',
    sortable: true,
  },
  {
    key: 'warehouse_name',
    label: 'Gudang',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '13px',
      color: 'black',
    },
    tdClass: 'text-black',
    class: 'p-1 align-top',
    sortable: true,
  },
  {
    key: 'total_orders',
    label: 'Total Order',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '13px',
      color: 'black',
    },
    tdClass: 'text-black',
    class: 'p-1 align-top',
    sortable: true,
  },
  {
    key: 'total_fulfillment',
    label: 'Nilai Fulfillment',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '13px',
      color: 'black',
    },
    tdClass: 'text-black',
    class: 'p-1 align-top',
    sortable: true,
    formatter: value => IDR(value).replace('.', ''),
  },
  {
    key: 'total_kompack_revenue',
    label: 'Revenue Kompack',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '13px',
      color: 'black',
    },
    tdClass: 'text-black',
    class: 'p-1 align-top',
    sortable: true,
    formatter: value => IDR(value).replace('.', ''),
  },
]
