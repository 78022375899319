<template>
  <BRow>
    <BCol lg="12">
      <div class="text-[24px] text-[#222222] font-semibold mb-[16px]">
        Dashboard
      </div>
      <BCard body>
        <div class="font-[600] text-[#222222] text-[16px]">
          Performa Pertumbuhan Partner
        </div>
        <div class="mt-1 grid grid-cols-1 sm:grid-cols-4 grid-rows-2 gap-4">
          <div class="border border-[#e2e2e2] rounded-2xl  p-1">
            <div class="flex items-center justify-between">
              <div class="flex items-center">
                <img
                  src="https://storage.googleapis.com/komerce/icon/Icon-regist.svg"
                  alt="Komerce"
                >
                <span class="text-[16px] font-medium text-black ml-[8px]">Registrasi</span>
              </div>
            </div>
            <div
              v-if="loadingCard.registration"
              class="!mt-8 !mb-[52px] flex justify-center"
            >
              <BSpinner
                variant="primary"
              />
            </div>
            <div
              v-else
              class="mt-2 flex"
            >
              <span class="text-black text-[24px] font-semibold border-r pr-2">
                {{ cardData.registration.total }}
              </span>
              <div class="ml-1">
                <div class="text-[#34A270]">
                  + {{ cardData.registration.current_week }} Minggu Ini
                </div>
                <div class="text-[#08A0F7]">
                  + {{ cardData.registration.previous_week }} Minggu Lalu
                </div>
              </div>
            </div>
          </div>
          <div class="border border-[#e2e2e2] rounded-2xl  p-1">
            <div class="flex items-center justify-between">
              <div class="flex items-center">
                <img
                  src="https://storage.googleapis.com/komerce/icon/Icon-berlangganan.svg"
                  alt="Komerce"
                >
                <span class="text-[16px] font-medium text-black ml-[8px]">Berlangganan</span>
              </div>
            </div>
            <div
              v-if="loadingCard.subscription"
              class="!mt-8 !mb-[52px] flex justify-center"
            >
              <BSpinner
                variant="primary"
              />
            </div>
            <div v-else>
              <div
                class="mt-2 flex"
              >
                <span class="text-black text-[24px] font-semibold border-r pr-2">
                  {{ cardData.subscription.total }}
                </span>
                <div class="ml-1">
                  <div class="text-[#34A270]">
                    + {{ cardData.subscription.current_week }} Minggu Ini
                  </div>
                  <div class="text-[#08A0F7]">
                    + {{ cardData.subscription.previous_week }} Minggu Lalu
                  </div>
                </div>
              </div>
              <div class="flex space-x-2 mt-[12px]">
                <BButton
                  variant="outline-primary"
                  size="sm"
                  @click="$router.push('/history-submission')"
                >
                  {{ cardData.subscription.pending }} pengajuan perlu persetujuan
                </BButton>
              </div>
            </div>
          </div>
          <div class="border border-[#e2e2e2] rounded-2xl  p-1">
            <div class="flex items-center justify-between">
              <div class="flex items-center">
                <img
                  src="https://storage.googleapis.com/komerce/icon/Icon-inbound.svg"
                  alt="Komerce"
                >
                <span class="text-[16px] font-medium text-black ml-[8px]">Inbound</span>
              </div>
            </div>
            <div
              v-if="loadingCard.inbound"
              class="!mt-8 !mb-[52px] flex justify-center"
            >
              <BSpinner
                variant="primary"
              />
            </div>
            <div
              v-else
              class="mt-2 flex"
            >
              <span class="text-black text-[24px] font-semibold border-r pr-2">
                {{ cardData.inbound.total }}
              </span>
              <div class="ml-1">
                <div class="text-[#34A270]">
                  + {{ cardData.inbound.current_week }} Minggu Ini
                </div>
                <div class="text-[#08A0F7]">
                  + {{ cardData.inbound.previous_week }} Minggu Lalu
                </div>
              </div>
            </div>
          </div>
          <div class="border border-[#e2e2e2] rounded-2xl  p-1">
            <div class="flex items-center justify-between">
              <div class="flex items-center">
                <img
                  src="https://storage.googleapis.com/komerce/icon/Icon-aktifberlanggananan.svg"
                  alt="Komerce"
                >
                <span class="text-[16px] font-medium text-black ml-[8px]">Aktif Berlangganan</span>
              </div>
            </div>
            <div
              v-if="loadingCard.activelySubscribed"
              class="!mt-8 !mb-[52px] flex justify-center"
            >
              <BSpinner
                variant="primary"
              />
            </div>
            <div v-else>
              <div
                class="mt-2 flex justify-center"
              >
                <span class="text-black text-[24px] font-semibold pr-2">
                  {{ cardData.activelySubscribed.total }}
                </span>
              </div>
              <div class="flex mt-[12px]">
                <flat-pickr
                  v-model="activePeriod"
                  :config="flatpickrMonthly"
                />
              </div>
            </div>
          </div>
          <div class="border border-[#e2e2e2] rounded-2xl  p-1">
            <div class="flex items-center justify-between">
              <div class="flex items-center">
                <img
                  src="https://storage.googleapis.com/komerce/icon/Icon-aktif.svg"
                  alt="Komerce"
                >
                <span class="text-[16px] font-medium text-black mx-[8px]">Aktif</span>
                <img
                  id="aktifInfo"
                  class="w-[20px] h-[20px]"
                  src="https://storage.googleapis.com/komerce/assets/icons/info-circle.svg"
                  alt="info"
                >
                <BPopover
                  target="aktifInfo"
                  triggers="hover"
                  placement="top"
                >User yang melakukan aktifitas dalam 30 hari terakhir</BPopover>
              </div>
            </div>
            <div
              v-if="loadingCard.active"
              class="!mt-8 !mb-[52px] flex justify-center"
            >
              <BSpinner
                variant="primary"
              />
            </div>
            <div v-else>
              <div
                class="mt-2 flex"
              >
                <span class="text-black text-[24px] font-semibold border-r pr-2">
                  {{ cardData.active.total }}
                </span>
                <div class="ml-1">
                  <div class="text-[#34A270]">
                    + {{ cardData.active.current_week }} Minggu Ini
                  </div>
                  <div class="text-[#08A0F7]">
                    + {{ cardData.active.previous_week }} Minggu Lalu
                  </div>
                </div>
              </div>
              <div class="flex space-x-2 mt-[16px]">
                <div class="text-sm text-black">
                  Bulan lalu: <span class="text-[#08A0F7]">+ {{ cardData.active.previous_month }}</span>
                </div>
                <div class="text-sm text-black">
                  Bulan ini: <span class="text-[#34A270]">+ {{ cardData.active.current_month }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="border border-[#e2e2e2] rounded-2xl  p-1">
            <div class="flex items-center justify-between">
              <div class="flex items-center">
                <img
                  src="https://storage.googleapis.com/komerce/icon/Icon-inaktif.svg"
                  alt="Komerce"
                >
                <span class="text-[16px] font-medium text-black mx-[8px]">In-aktif</span>
                <img
                  id="inaktifInfo"
                  class="w-[20px] h-[20px]"
                  src="https://storage.googleapis.com/komerce/assets/icons/info-circle.svg"
                  alt="info"
                >
                <BPopover
                  target="inaktifInfo"
                  triggers="hover"
                  placement="top"
                >User yang tidak melakukan aktifitas selama &gt; 30 hari dan &lt; 60 hari</BPopover>
              </div>
            </div>
            <div
              v-if="loadingCard.inactive"
              class="!mt-8 !mb-[52px] flex justify-center"
            >
              <BSpinner
                variant="primary"
              />
            </div>
            <div
              v-else
              class="mt-2 flex justify-center"
            >
              <span class="text-black text-[24px] font-semibold pr-1">
                {{ cardData.inactive.total }}
              </span>
            </div>
          </div>
          <div class="border border-[#e2e2e2] rounded-2xl  p-1">
            <div class="flex items-center justify-between">
              <div class="flex items-center">
                <img
                  src="https://storage.googleapis.com/komerce/icon/Icon-pasif.svg"
                  alt="Komerce"
                >
                <span class="text-[16px] font-medium text-black mx-[8px]">Pasif</span>
                <img
                  id="pasifInfo"
                  class="w-[20px] h-[20px]"
                  src="https://storage.googleapis.com/komerce/assets/icons/info-circle.svg"
                  alt="info"
                >
                <BPopover
                  target="pasifInfo"
                  triggers="hover"
                  placement="top"
                >User yang tidak melakukan aktifitas selama lebih dari 60 hari</BPopover>
              </div>
            </div>
            <div
              v-if="loadingCard.passive"
              class="!mt-8 !mb-[52px] flex justify-center"
            >
              <BSpinner
                variant="primary"
              />
            </div>
            <div
              v-else
              class="mt-2 flex justify-center"
            >
              <span class="text-black text-[24px] font-semibold pr-2">
                {{ cardData.passive.total }}
              </span>
            </div>
          </div>
          <div class="border border-[#e2e2e2] rounded-2xl  p-1">
            <div class="flex items-center justify-between">
              <div class="flex items-center">
                <img
                  src="https://storage.googleapis.com/komerce/icon/Icon-jumlahpacking.svg"
                  alt="Komerce"
                >
                <span class="text-[16px] font-medium text-black ml-[8px]">Jumlah Packing</span>
              </div>
            </div>
            <div
              v-if="loadingCard.packing"
              class="!mt-8 !mb-[52px] flex justify-center"
            >
              <BSpinner
                variant="primary"
              />
            </div>
            <div v-else>
              <div class="mt-2 flex">
                <span class="text-black text-[24px] font-semibold border-r pr-2">
                  {{ cardData.packing.total }}
                </span>
                <div class="ml-1">
                  <div class="text-[#FBBC05]">
                    Komship : {{ cardData.packing.komship }}
                  </div>
                  <div class="text-[#08A0F7]">
                    Non-Komship : {{ cardData.packing.nonKomship }}
                  </div>
                </div>
              </div>
              <div class="flex mt-[12px]">
                <flat-pickr
                  v-model="packingPeriod"
                  :config="flatpickrMonthly"
                />
              </div>
            </div>
          </div>
        </div>
      </BCard>
      <BCard body>
        <div class="border rounded-lg">
          <div class="flex justify-between items-center px-[16px] py-[20px]">
            <div class="font-[600] text-[#222222] text-[16px]">
              Ringkasan
            </div>
            <DateRangePicker
              ref="picker"
              v-model="summaryPeriod"
              :locale-data="locale"
              :ranges="ranges"
              :opens="'left'"
              class="!min-w-[352px]"
            >
              <template v-slot:input="picker">
                <div class="d-flex justify-content-between align-items-center w-100">
                  <img
                    src="https://storage.googleapis.com/komerce/assets/icons/calendar.png"
                    alt="Komerce"
                  >
                  <div class="ml-1">
                    <span
                      style="color: #828282 !important"
                    >
                      {{ DAY_MONTH_YEAR(picker.startDate) }} - {{ DAY_MONTH_YEAR(picker.endDate) }}
                    </span>
                  </div>
                </div>
              </template>
            </DateRangePicker>
          </div>
          <div class="grid grid-cols-3 border-t">
            <div class="px-[32px] py-[38px]">
              <div class="flex items-center space-x-2">
                <img
                  src="https://storage.googleapis.com/komerce/icon/icon-total.svg"
                  alt="total"
                  class="w-[24px] h-[24px]"
                >
                <div class="text-[16px] text-[#222222] font-medium">
                  Total Nilai Transaksi
                </div>

              </div>
              <b-skeleton
                v-if="loadingSummary"
                class="mt-[22px]"
                width="85%"
                height="20px"
              />
              <div
                v-else
                class="text-[24px] text-[#222222] font-semibold mt-[22px]"
              >
                Rp{{ IDR(summaryData.total).substring(3) }},-
              </div>
            </div>
            <div class="px-[32px] py-[38px] border-r border-l">
              <div class="flex items-center space-x-2">
                <img
                  src="https://storage.googleapis.com/komerce/icon/icon-penghasilan.svg"
                  alt="total"
                  class="w-[24px] h-[24px]"
                >
                <div class="text-[16px] text-[#222222] font-medium">
                  Penghasilan Mitra
                </div>

              </div>
              <b-skeleton
                v-if="loadingSummary"
                class="mt-[22px]"
                width="85%"
                height="20px"
              />
              <div
                v-else
                class="text-[24px] text-[#222222] font-semibold mt-[22px]"
              >
                Rp{{ IDR(summaryData.mitra_earnings).substring(3) }},-
              </div>
            </div>
            <div class="px-[32px] py-[38px] relative overflow-hidden">
              <img
                src="https://storage.googleapis.com/komerce/icon/Logo-product.svg"
                alt="kompack"
                class="absolute -top-24 -right-40"
              >
              <div class="flex items-center space-x-2">
                <img
                  src="https://storage.googleapis.com/komerce/assets/svg/logo_kompack.svg"
                  alt="total"
                  class="w-[24px] h-[24px]"
                >
                <div class="text-[16px] text-[#222222] font-medium">
                  Penghasilan Kompack
                </div>

              </div>
              <b-skeleton
                v-if="loadingSummary"
                class="mt-[22px]"
                width="85%"
                height="20px"
              />
              <div
                v-else
                class="text-[24px] text-[#222222] font-semibold mt-[22px]"
              >
                Rp{{ IDR(summaryData.kompack_revenue).substring(3) }},-
              </div>
            </div>
          </div>
        </div>

        <div class="border rounded-lg mt-[21px]">
          <div class="flex justify-between items-center px-[16px] py-[20px]">
            <div class="font-[600] text-[#222222] text-[16px]">
              Performa Mitra Gudang
            </div>
            <div class="flex gap-[10px]">
              <BFormInput
                v-model="paramsPerformaMitra.warehouse_name"
                placeholder="Cari nama mitra"
                class="!w-[250px]"
                debounce="1000"
              />
              <DateRangePicker
                ref="picker"
                v-model="performaMitraPeriod"
                :locale-data="locale"
                :ranges="ranges"
                :opens="'left'"
                class="!min-w-[352px]"
              >
                <template v-slot:input="picker">
                  <div class="d-flex justify-content-between align-items-center w-100">
                    <img
                      src="https://storage.googleapis.com/komerce/assets/icons/calendar.png"
                      alt="Komerce"
                    >
                    <div class="ml-1">
                      <span style="color: #828282 !important">
                        {{ DAY_MONTH_YEAR(picker.startDate) }} - {{ DAY_MONTH_YEAR(picker.endDate) }}
                      </span>
                    </div>
                  </div>
                </template>
              </DateRangePicker>
              <BButton
                variant="outline-primary"
                size="sm"
                class="!flex items-center !w-[130px] justify-between"
                :disbaled="loadingDownloadPerforma.mitra"
                @click="downloadPerformaMitra"
              >
                <b-spinner
                  v-if="loadingDownloadPerforma.mitra"
                  small
                />
                <img
                  v-else
                  src="https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/document-download.svg"
                  alt="download"
                  width="20"
                  height="20"
                >
                Download
              </BButton>
            </div>
          </div>
          <BOverlay
            :show="loadingPerforma.mitra"
            spinner-variant="primary"
            variant="light"
            blur="0"
            opacity=".5"
            rounded="sm"
          >
            <BTable
              :items="performaData.mitra"
              :fields="performaMitraFields"
              show-empty
              empty-text="Tidak ada data yang ditampilkan."
              responsive
              sticky-header="725px"
              @scroll.native="handleScrollMitra"
            >
              <template #cell(warehouse_name)="row">
                <div class="flex gap-[8px]">
                  <img
                    v-if="!row.item.warehouse_image"
                    src="https://storage.googleapis.com/komerce/assets/icons/product-placehold.svg"
                    alt="fotoGudang"
                    class="h-[38px] w-[38px]"
                  >
                  <img
                    v-else
                    :src="row.item.warehouse_image"
                    alt="fotoGudang"
                    class="h-[38px] w-[38px] object-cover"
                  >
                  <div class="">
                    <div class="text-[#222222]">
                      {{ row.item.warehouse_name }}
                    </div>
                    <div class="text-primary text-[12px ]">
                      {{ row.item.mitra_name }}
                    </div>
                  </div>
                </div>
              </template>
            </BTable>
          </BOverlay>
        </div>

        <div class="border rounded-lg mt-[21px]">
          <div class="flex justify-between items-center px-[16px] py-[20px]">
            <div class="font-[600] text-[#222222] text-[16px]">
              Performa Partner
            </div>
            <div class="flex gap-[10px]">
              <BFormInput
                v-model="paramsPerformaPartner.fullname"
                placeholder="Cari nama partner"
                class="!w-[250px]"
                debounce="1000"
              />
              <DateRangePicker
                ref="picker"
                v-model="performaPartnerPeriod"
                :locale-data="locale"
                :ranges="ranges"
                :opens="'left'"
                class="!min-w-[352px]"
              >
                <template v-slot:input="picker">
                  <div class="d-flex justify-content-between align-items-center w-100">
                    <img
                      src="https://storage.googleapis.com/komerce/assets/icons/calendar.png"
                      alt="Komerce"
                    >
                    <div class="ml-1">
                      <span style="color: #828282 !important">
                        {{ DAY_MONTH_YEAR(picker.startDate) }} - {{ DAY_MONTH_YEAR(picker.endDate) }}
                      </span>
                    </div>
                  </div>
                </template>
              </DateRangePicker>
              <BButton
                variant="outline-primary"
                size="sm"
                class="!flex items-center !w-[130px] justify-between"
                :disbaled="loadingDownloadPerforma.partner"
                @click="downloadPerformaPartner"
              >
                <b-spinner
                  v-if="loadingDownloadPerforma.partner"
                  small
                />
                <img
                  v-else
                  src="https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/document-download.svg"
                  alt="download"
                  width="20"
                  height="20"
                >
                Download
              </BButton>
            </div>
          </div>
          <BOverlay
            :show="loadingPerforma.partner"
            spinner-variant="primary"
            variant="light"
            blur="0"
            opacity=".5"
            rounded="sm"
          >
            <BTable
              :items="performaData.partner"
              :fields="performaPartnerFields"
              show-empty
              empty-text="Tidak ada data yang ditampilkan."
              responsive
              sticky-header="725px"
              @scroll.native="handleScrollPartner"
            >
              <template #cell(partner_name)="row">
                <div class="flex gap-[8px]">
                  <img
                    v-if="!row.item.partner_image"
                    src="https://storage.googleapis.com/komerce/assets/icons/product-placehold.svg"
                    alt="fotoGudang"
                    class="h-[38px] w-[38px]"
                  >
                  <img
                    v-else
                    :src="row.item.partner_image"
                    alt="fotoGudang"
                    class="h-[38px] w-[38px] object-cover"
                  >
                  <div class="">
                    <div class="text-[#222222]">
                      {{ row.item.partner_name }}
                    </div>
                    <div class="text-primary text-[12px ]">
                      {{ row.item.partner_phone }}
                    </div>
                  </div>
                </div>
              </template>
            </BTable>
          </BOverlay>
        </div>
      </BCard>
    </BCol>
  </BRow>
</template>
<script>
import { axiosIns, newAxiosIns } from '@/libs/axios'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import DateRangePicker from 'vue2-daterange-picker'
import {
  firstDateOfMonth,
  lastDateOfMonth,
  last30,
  last7,
  today,
} from '@/store/helpers'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import {
  LABELDAY, LABELMONTH, DAY_MONTH_YEAR, YEAR_MONTH_DAY,
} from '@/libs/filterDate'
import { IDR } from '@/libs/currency'
import MonthSelect from 'flatpickr/dist/plugins/monthSelect'
import flatPickr from 'vue-flatpickr-component'
import { Indonesian } from 'flatpickr/dist/l10n/id'
import '@/@core/scss/vue/libs/vue-flatpicker.scss'
import 'flatpickr/dist/plugins/monthSelect/style.css'
import { performaMitraFields, performaPartnerFields } from './config'

export default {
  components: {
    DateRangePicker,
    flatPickr,
  },
  data() {
    return {
      locale: {
        format: 'dd/mm/yyyy',
        daysOfWeek: LABELDAY,
        monthNames: LABELMONTH,
      },
      ranges: {
        '7 Hari Terakhir': [last7, today],
        '1 Bulan terakhir': [last30, today],
        'Bulan ini': [firstDateOfMonth, lastDateOfMonth],
        'Custom Tanggal ': [today, today],
      },
      firstDateOfMonth,
      last30,
      last7,
      today,
      YEAR_MONTH_DAY,
      DAY_MONTH_YEAR,
      IDR,
      performaMitraFields,
      performaPartnerFields,

      flatpickrMonthly: {
        mode: 'single',
        locale: Indonesian,
        altInput: true,
        maxDate: today,
        plugins: [
          new MonthSelect({
            shorthand: true,
            dateFormat: 'Y-m-d',
            altFormat: 'M Y',
          }),
        ],
      },

      loadingCard: {
        registration: false,
        subscription: false,
        inbound: false,
        activelySubscribed: false,
        active: false,
        inactive: false,
        passive: false,
        packing: false,
      },

      cardData: {
        registration: {
          total: 0,
          current_week: 0,
          previous_week: 0,
        },
        subscription: {
          total: 0,
          current_week: 0,
          previous_week: 0,
          pending: 0,
        },
        inbound: {
          total: 0,
          current_week: 0,
          previous_week: 0,
        },
        activelySubscribed: {
          total: 0,
        },
        active: {
          total: 0,
          current_week: 0,
          previous_week: 0,
          current_month: 0,
          previous_month: 0,
        },
        inactive: {
          total: 0,
        },
        passive: {
          total: 0,
        },
        packing: {
          total: 0,
          komship: 0,
          nonKomship: 0,
        },
      },

      activePeriod: YEAR_MONTH_DAY(today),
      packingPeriod: YEAR_MONTH_DAY(today),

      loadingSummary: true,

      summaryData: {
        total: 0,
        mitra_earnings: 0,
        kompack_revenue: 0,
      },

      summaryPeriod: {
        startDate: firstDateOfMonth,
        endDate: lastDateOfMonth,
      },

      loadingPerforma: {
        mitra: false,
        partner: false,
      },

      loadingDownloadPerforma: {
        mitra: false,
        partner: false,
      },

      performaMitraPeriod: {
        startDate: firstDateOfMonth,
        endDate: lastDateOfMonth,
      },

      paramsPerformaMitra: {
        limit: 10,
        offset: 0,
        warehouse_name: '',
        lastData: false,
      },

      performaPartnerPeriod: {
        startDate: firstDateOfMonth,
        endDate: lastDateOfMonth,
      },

      paramsPerformaPartner: {
        limit: 10,
        offset: 0,
        fullname: '',
        lastData: false,
      },

      performaData: {
        mitra: [],
        partner: [],
      },
    }
  },
  watch: {
    activePeriod: {
      handler() {
        this.getActivelySubscribed()
      },
    },

    packingPeriod: {
      handler() {
        this.getPacking()
      },
    },

    summaryPeriod: {
      handler() {
        this.getSummaryData()
      },
    },

    'paramsPerformaMitra.warehouse_name': {
      handler() {
        this.getPerformaMitra()
      },
    },
    performaMitraPeriod: {
      handler() {
        this.getPerformaMitra()
      },
    },

    'paramsPerformaPartner.fullname': {
      handler() {
        this.getPerformaPartner()
      },
    },
    performaPartnerPeriod: {
      handler() {
        this.getPerformaPartner()
      },
    },
  },
  created() {
    this.getPerformaMitra()
    this.getPerformaPartner()

    // Card Data
    this.getTotalRegister()
    this.getTotalSubscription()
    this.getTotalInbounds()
    this.getTotalActive()
    this.getTotalInactive()
    this.getTotalPassive()
    this.getActivelySubscribed()
    this.getPacking()

    // Summary Data
    this.getSummaryData()
  },
  methods: {
    // Performa
    async getPerformaMitra() {
      this.loadingPerforma.mitra = true
      this.paramsPerformaMitra.offset = 0
      const url = 'kompack/api/v1/dashboard/admin/performances/mitra'
      const params = {
        limit: this.paramsPerformaMitra.limit,
        offset: this.paramsPerformaMitra.offset,
        warehouse_name: this.paramsPerformaMitra.warehouse_name,
        start_date: YEAR_MONTH_DAY(this.performaMitraPeriod.startDate),
        end_date: YEAR_MONTH_DAY(this.performaMitraPeriod.endDate),
      }
      await newAxiosIns.get(url, { params })
        .then(res => {
          const { data } = res.data

          this.performaData.mitra = data
          this.loadingPerforma.mitra = false

          if (data.length < this.paramsPerformaMitra.limit) {
            this.paramsPerformaMitra.lastData = true
          } else {
            this.paramsPerformaMitra.offset += data.length
            this.paramsPerformaMitra.lastData = false
          }
        })
        .catch(err => {
          this.loadingPerforma.mitra = false
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: err.response.message,
              variant: 'danger',
            },
          }, { timeout: 2000 })
        })
    },
    async getNextDataPerformaMitra() {
      this.loadingPerforma.mitra = true
      const url = 'kompack/api/v1/dashboard/admin/performances/mitra'
      const params = {
        limit: this.paramsPerformaMitra.limit,
        offset: this.paramsPerformaMitra.offset,
        warehouse_name: this.paramsPerformaMitra.warehouse_name,
        start_date: YEAR_MONTH_DAY(this.performaMitraPeriod.startDate),
        end_date: YEAR_MONTH_DAY(this.performaMitraPeriod.endDate),
      }
      await newAxiosIns.get(url, { params })
        .then(res => {
          const { data } = res.data

          this.performaData.mitra.push(...data)
          this.paramsPerformaMitra.offset += data.length

          if (data.length < this.paramsPerformaMitra.limit) {
            this.paramsPerformaMitra.lastData = true
          }

          this.loadingPerforma.mitra = false
        })
        .catch(err => {
          this.loadingPerforma.mitra = false
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: err.response.message,
              variant: 'danger',
            },
          }, { timeout: 2000 })
        })
    },
    async getPerformaPartner() {
      this.loadingPerforma.partner = true
      this.paramsPerformaPartner.offset = 0
      const url = 'kompack/api/v1/dashboard/admin/performances/partner'
      const params = {
        limit: this.paramsPerformaPartner.limit,
        offset: this.paramsPerformaPartner.offset,
        fullname: this.paramsPerformaPartner.fullname,
        start_date: YEAR_MONTH_DAY(this.performaPartnerPeriod.startDate),
        end_date: YEAR_MONTH_DAY(this.performaPartnerPeriod.endDate),
      }
      await newAxiosIns.get(url, { params })
        .then(res => {
          const { data } = res.data

          this.performaData.partner = data

          if (data.length < this.paramsPerformaPartner.limit) {
            this.paramsPerformaPartner.lastData = true
          } else {
            this.paramsPerformaPartner.offset += data.length
            this.paramsPerformaPartner.lastData = false
          }

          this.loadingPerforma.partner = false
        })
        .catch(err => {
          this.loadingPerforma.partner = false
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: err.response.message,
              variant: 'danger',
            },
          }, { timeout: 2000 })
        })
    },
    async getNextDataPerformaPartner() {
      this.loadingPerforma.partner = true
      const url = 'kompack/api/v1/dashboard/admin/performances/partner'
      const params = {
        limit: this.paramsPerformaPartner.limit,
        offset: this.paramsPerformaPartner.offset,
        fullname: this.paramsPerformaPartner.fullname,
        start_date: YEAR_MONTH_DAY(this.performaPartnerPeriod.startDate),
        end_date: YEAR_MONTH_DAY(this.performaPartnerPeriod.endDate),
      }
      await newAxiosIns.get(url, { params })
        .then(res => {
          const { data } = res.data

          this.performaData.partner.push(...data)
          this.paramsPerformaPartner.offset += data.length

          if (data.length < this.paramsPerformaPartner.limit) {
            this.paramsPerformaPartner.lastData = true
          }

          this.loadingPerforma.partner = false
        })
        .catch(err => {
          this.loadingPerforma.partner = false
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: err.response.message,
              variant: 'danger',
            },
          }, { timeout: 2000 })
        })
    },
    handleScrollMitra(event) {
      const { scrollTop, scrollHeight, clientHeight } = event.target
      if (scrollTop + clientHeight >= scrollHeight - 1 && !this.paramsPerformaMitra.lastData) {
        this.getNextDataPerformaMitra()
      }
    },
    handleScrollPartner(event) {
      const { scrollTop, scrollHeight, clientHeight } = event.target
      if (scrollTop + clientHeight >= scrollHeight - 1 && !this.paramsPerformaPartner.lastData) {
        this.getNextDataPerformaPartner()
      }
    },
    async downloadPerformaMitra() {
      this.loadingDownloadPerforma.mitra = true
      const apiUrl = 'kompack/api/v1/dashboard/admin/performances/mitra/download'
      const body = {
        start_date: YEAR_MONTH_DAY(this.performaMitraPeriod.startDate),
        end_date: YEAR_MONTH_DAY(this.performaMitraPeriod.endDate),
      }
      await newAxiosIns.post(apiUrl, body, { responseType: 'blob' })
        .then(res => {
          const url = window.URL.createObjectURL(new Blob([res.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `performa_mitra_${DAY_MONTH_YEAR(this.performaMitraPeriod.startDate)} - ${DAY_MONTH_YEAR(this.performaMitraPeriod.endDate)}.xlsx`)
          document.body.appendChild(link)
          link.click()
          link.remove()
          window.URL.revokeObjectURL(url)
          this.loadingDownloadPerforma.mitra = false
        })
        .catch(err => {
          this.loadingDownloadPerforma.mitra = false
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: err.response.message,
              variant: 'danger',
            },
          }, { timeout: 2000 })
        })
    },
    async downloadPerformaPartner() {
      this.loadingDownloadPerforma.partner = true
      const apiUrl = 'kompack/api/v1/dashboard/admin/performances/partner/download'
      const body = {
        start_date: YEAR_MONTH_DAY(this.performaPartnerPeriod.startDate),
        end_date: YEAR_MONTH_DAY(this.performaPartnerPeriod.endDate),
      }
      await newAxiosIns.post(apiUrl, body, { responseType: 'blob' })
        .then(res => {
          const url = window.URL.createObjectURL(new Blob([res.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `performa_partner_${DAY_MONTH_YEAR(this.performaPartnerPeriod.startDate)} - ${DAY_MONTH_YEAR(this.performaPartnerPeriod.endDate)}.xlsx`)
          document.body.appendChild(link)
          link.click()
          link.remove()
          window.URL.revokeObjectURL(url)
          this.loadingDownloadPerforma.partner = false
        })
        .catch(err => {
          this.loadingDownloadPerforma.partner = false
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: err.response.message,
              variant: 'danger',
            },
          }, { timeout: 2000 })
        })
    },

    // Card Data
    async getTotalRegister() {
      this.loadingCard.registration = true
      const url = '/auth/api/v1/admin/dashboard/total-register'
      await newAxiosIns.get(url)
        .then(res => {
          const { data } = res.data
          this.cardData.registration.total = data.total
          this.cardData.registration.current_week = data.current_week
          this.cardData.registration.previous_week = data.previous_week
          this.loadingCard.registration = false
        })
        .catch(err => {
          this.loadingCard.registration = false
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: 'Gagal mengambil data, refresh halaman ini',
              variant: 'danger',
            },
          }, { timeout: 2000 })
        })
    },
    async getTotalSubscription() {
      this.loadingCard.subscription = true
      const url = 'komship/api/v1/admin/subscribe-warehouses/total-subscriptions'
      await newAxiosIns.get(url)
        .then(res => {
          const { data } = res.data
          this.cardData.subscription.total = data.total
          this.cardData.subscription.current_week = data.current_week
          this.cardData.subscription.previous_week = data.previous_week
          this.cardData.subscription.pending = data.pending
          this.loadingCard.subscription = false
        })
        .catch(err => {
          this.loadingCard.subscription = false
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: 'Gagal mengambil data, refresh halaman ini',
              variant: 'danger',
            },
          }, { timeout: 2000 })
        })
    },
    async getTotalInbounds() {
      this.loadingCard.inbound = true
      const url = 'komship/api/v1/admin/inbounds/total-inbounds'
      await newAxiosIns.get(url)
        .then(res => {
          const { data } = res.data
          this.cardData.inbound.total = data.total
          this.cardData.inbound.current_week = data.current_week
          this.cardData.inbound.previous_week = data.previous_week
          this.loadingCard.inbound = false
        })
        .catch(err => {
          this.loadingCard.inbound = false
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: 'Gagal mengambil data, refresh halaman ini',
              variant: 'danger',
            },
          }, { timeout: 2000 })
        })
    },
    async getTotalActive() {
      this.loadingCard.active = true
      const url = '/kompack/api/v1/dashboard/admin/statistics/users/active'
      await newAxiosIns.get(url)
        .then(res => {
          const { data } = res.data
          this.cardData.active.total = data.total
          this.cardData.active.current_week = data.current_week
          this.cardData.active.previous_week = data.previous_week
          this.cardData.active.current_month = data.current_month
          this.cardData.active.previous_month = data.previous_month
          this.loadingCard.active = false
        })
        .catch(err => {
          this.loadingCard.active = false
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: 'Gagal mengambil data, refresh halaman ini',
              variant: 'danger',
            },
          }, { timeout: 2000 })
        })
    },
    async getTotalInactive() {
      this.loadingCard.inactive = true
      const url = '/kompack/api/v1/dashboard/admin/statistics/users/inactive'
      await newAxiosIns.get(url)
        .then(res => {
          const { data } = res.data
          this.cardData.inactive.total = data.total
          this.loadingCard.inactive = false
        })
        .catch(err => {
          this.loadingCard.inactive = false
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: 'Gagal mengambil data, refresh halaman ini',
              variant: 'danger',
            },
          }, { timeout: 2000 })
        })
    },
    async getTotalPassive() {
      this.loadingCard.passive = true
      const url = '/kompack/api/v1/dashboard/admin/statistics/users/passive'
      await newAxiosIns.get(url)
        .then(res => {
          const { data } = res.data
          this.cardData.passive.total = data.total
          this.loadingCard.passive = false
        })
        .catch(err => {
          this.loadingCard.passive = false
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: 'Gagal mengambil data, refresh halaman ini',
              variant: 'danger',
            },
          }, { timeout: 2000 })
        })
    },
    async getActivelySubscribed() {
      this.loadingCard.activelySubscribed = true
      const url = 'kompack/api/v1/dashboard/admin/statistics/active-subscriptions'
      const params = {
        period: this.activePeriod,
      }
      await newAxiosIns.get(url, { params })
        .then(res => {
          const { data } = res.data
          this.cardData.activelySubscribed.total = data.total
          this.loadingCard.activelySubscribed = false
        })
        .catch(err => {
          this.loadingCard.activelySubscribed = false
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: 'Gagal mengambil data, refresh halaman ini',
              variant: 'danger',
            },
          }, { timeout: 2000 })
        })
    },
    async getPacking() {
      this.loadingCard.packing = true
      const url = '/kompack/api/v1/dashboard/admin/statistics/packing-requests'
      const params = {
        period: this.packingPeriod,
      }
      await newAxiosIns.get(url, { params })
        .then(res => {
          const { data } = res.data
          this.cardData.packing.total = data.total
          this.cardData.packing.komship = data.komship
          this.cardData.packing.nonKomship = data.non_komship
          this.loadingCard.packing = false
        })
        .catch(err => {
          this.loadingCard.packing = false
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: 'Gagal mengambil data, refresh halaman ini',
              variant: 'danger',
            },
          }, { timeout: 2000 })
        })
    },

    // Summary Data
    async getSummaryData() {
      this.loadingSummary = true
      const url = '/komship/api/v1/admin/transactions/fulfillment-summaries'
      const params = {
        start_date: YEAR_MONTH_DAY(this.summaryPeriod.startDate),
        end_date: YEAR_MONTH_DAY(this.summaryPeriod.endDate),
      }
      await newAxiosIns.get(url, { params })
        .then(res => {
          const { data } = res.data
          this.summaryData.total = data.total
          this.summaryData.mitra_earnings = data.mitra_earnings
          this.summaryData.kompack_revenue = data.kompack_revenue
          this.loadingSummary = false
        })
        .catch(err => {
          this.loadingSummary = false
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: err.response.message,
              variant: 'danger',
            },
          }, { timeout: 2000 })
        })
    },
  },
}
</script>

<style>
.flatpickr-monthSelect-month.selected{
  background-color: #F95031 !important;
}
.flatpickr-monthSelect-month{
    font-weight: 600 !important;
}
.flatpickr-current-month input.cur-year{
    font-weight: 600 !important;
}
</style>
